import KroDropdown from "features/components/dropdowns/kroDropdown";
import { KroInputFieldType } from "features/campaign/domain/enums/kroInputFieldType.enum";
import KroTextField from "features/components/inputs/kroTextField";
import { KroButton } from "features/components/buttons/kroButton";
import { useCategories } from "../hooks/useCategory";
import { useOnboardingForm } from "../context/OnboardingContextProvider";
import { useForm } from "@tanstack/react-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdateOrganizationStepOne } from "../hooks/useOrganizationSetUp";
import KroLoadingIndicator from "features/components/loader/kroCircularIndicator";
import Routes from "router/routes";
import { useSendOTP } from "../hooks/useOTP";
import KroPhoneNumberCountryCodeDropDown from "features/components/dropdowns/kroPhoneNumberCodeDropdown";
import Validation from "common/utils/validation";
import Header from "../components/header";
import PhoneVerificationModal from "../components/create_account/PhoneVerficationModal";
import { useEffect, useState } from "react";
import KroLoader from "features/components/loader/kroLoader";
import { EOTPUseCases } from "features/onboarding/domain/enum/otpUseCase";
import { toast } from "react-toastify";
import { KroPhoneNumberUtil } from "common/utils/phoneNumber";
import { useGeoLocation } from "common/hooks/useGeolocator";
import CountryList from "country-list-with-dial-code-and-flag";

const OrganizationStepOne = () => {
  const { formData, setFormData, validateStepAccess } = useOnboardingForm();
  const location = useLocation();
  const { location: locationData } = useGeoLocation();

  useEffect(() => {
    validateStepAccess(location.pathname);
  }, [location, validateStepAccess]);

  useEffect(() => {
    if (locationData) {
      const foundCountry = CountryList.findOneByCountryCode(locationData);
      if (foundCountry) {
        form.setFieldValue("country", foundCountry.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  const navigate = useNavigate();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const handleCloseVerificationModal = (isVerified: boolean) => {
    if (isVerified) form.setFieldValue("isPhoneNumberVerified", isVerified);
    setShowVerificationModal(false);
  };
  const handleShowVerificationModal = () => {
    setShowVerificationModal(true);
  };

  const {
    categories,
    isLoading: isFetchingCategories,
    error: categoryFetchError,
  } = useCategories();

  const form = useForm({
    defaultValues: {
      category: formData.category ?? "",
      country: formData.country ?? "",
      phoneNumberCountry: formData.phoneNumberCountry,
      isPhoneNumberVerified: formData.isPhoneVerified ?? false,
      admin_first_name: formData.firstName ?? "",
      admin_last_name: formData.lastName ?? "",
      admin_phone_number: formData.phoneNumber ?? "",
    },

    onSubmit: (values) => {
      const payload: IOrganizationStepOnePayload = {
        category: values.value.category,
        country: values.value.country,
        admin_first_name: values.value.admin_first_name,
        admin_last_name: values.value.admin_last_name,
        admin_phone_number: values.value.admin_phone_number,
      };
      updateOrganizationStepOneMutation.mutate({ id: formData.id, payload });
    },
  });

  const updateOrganizationStepOneMutation = useUpdateOrganizationStepOne(
    (error: Error) => {
      toast.error(error.message);
    },
    (response: IOrganizationStepOneData) => {
      setFormData((prevData: any) => ({
        ...prevData,
        category: response.category,
        country: response.country,
        phoneNumberCountry: form.state.values.phoneNumberCountry,
        firstName: response.admin_first_name,
        lastName: response.admin_last_name,
        isPhoneVerified: true,
        phoneNumber: response.admin_phone_number,
      }));

      navigate(Routes.ORGANISATION_STEP_TWO);
    },
  );

  const OTPRequestMutation = useSendOTP(
    (error: Error) => {
      toast.error(error.message);
    },
    () => handleShowVerificationModal(),
  );

  const adminPhoneNumber = form.useStore(
    (state) => state.values.admin_phone_number,
  );
  const isPhoneNumberVerified = form.useStore(
    (state) => state.values.isPhoneNumberVerified,
  );
  const phoneNumberCountryCode = form.useStore(
    (state) => state.values.phoneNumberCountry,
  );

  if (categoryFetchError) {
    return (
      <KroLoadingIndicator
        error={categoryFetchError?.message}
        onRetry={() => window.location.reload()}
      />
    );
  }

  const countries = CountryList.getAll();
  const countryOptions = countries.map((country) => country.name);

  return (
    <>
      <PhoneVerificationModal
        type={EOTPUseCases.ORG_SIGNUP}
        phone={form.state.values.admin_phone_number}
        onClose={handleCloseVerificationModal}
        isOpen={showVerificationModal}
      />

      <form
        onSubmit={(e) => {
          form.handleSubmit();
          e.preventDefault();
        }}
        className="flex w-full flex-col items-center justify-center p-3 lg:p-6"
      >
        <Header
          title="Set Up Organisation"
          subtitle="Share more details with us to tailor your experience to your needs."
        ></Header>

        <div className="flex w-full flex-col gap-4">
          <form.Field
            name="category"
            validators={{
              onChange: ({ value }) => Validation.required(value),
            }}
            children={(field) => (
              <KroDropdown
                id={field.name}
                fillColor="bg-neutral-50"
                label={"Category"}
                errorMessage={field.state.meta.errors.join(", ")}
                placeHolder={"Select a category"}
                selectedValue={field.state.value}
                className={""}
                options={
                  !isFetchingCategories
                    ? (categories?.map((category) => category.name) ?? [])
                    : []
                }
                onDropdownItemClicked={(option) => field.handleChange(option)}
              />
            )}
          />

          <form.Field
            name="country"
            validators={{
              onChange: ({ value }) => Validation.required(value),
            }}
            children={(field) => (
              <KroDropdown
                id={field.name}
                fillColor="bg-neutral-50"
                label={"Country"}
                errorMessage={field.state.meta.errors.join(", ")}
                placeHolder={"Select a country"}
                selectedValue={field.state.value}
                className={""}
                options={countryOptions}
                onDropdownItemClicked={(option) => field.handleChange(option)}
              />
            )}
          />
        </div>

        <div className="my-9 flex w-full items-center gap-2">
          <div className="leading-3 text-[11px] font-medium uppercase tracking-tight text-neutral-400">
            ADMIN DETAILS
          </div>
          <div className="flex-grow border-b border-neutral-200"></div>
        </div>

        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full flex-col gap-4 md:flex-row">
            <form.Field
              name="admin_first_name"
              validators={{
                onChange: ({ value }) => Validation.required(value),
              }}
              children={(field) => (
                <KroTextField
                  label={"First Name"}
                  margin="mb-0"
                  fillColor="bg-neutral-50"
                  type={KroInputFieldType.TEXT}
                  className="flex-1"
                  placeHolder={"John"}
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  errorMessage={field.state.meta.errors.join(", ")}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            />

            <form.Field
              name="admin_last_name"
              validators={{
                onChange: ({ value }) => Validation.required(value),
              }}
              children={(field) => (
                <KroTextField
                  label={"Last Name"}
                  margin="mb-0"
                  className="flex-1"
                  fillColor="bg-neutral-50"
                  type={KroInputFieldType.TEXT}
                  placeHolder={"Doe"}
                  errorMessage={field.state.meta.errors.join(", ")}
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            />
          </div>

          <div className="w-full">
            <form.Subscribe>
              {(state) => {
                return (
                  <form.Field
                    validators={{
                      onChange: ({ value }) => {
                        return Validation.phoneNumberByCountryCode(
                          value,
                          state.values.phoneNumberCountry,
                        );
                      },
                    }}
                    name="admin_phone_number"
                    children={(field) => (
                      <KroTextField
                        leadingAddon={
                          !locationData ? (
                            <div></div>
                          ) : (
                            <form.Field
                              name="phoneNumberCountry"
                              children={(field) => {
                                return (
                                  <KroPhoneNumberCountryCodeDropDown
                                    value={field.state.value}
                                    userCountryCode={locationData ?? ""}
                                    onChange={(value) =>
                                      field.handleChange(value.dialCode)
                                    }
                                  />
                                );
                              }}
                            ></form.Field>
                          )
                        }
                        label={"Phone Number"}
                        margin="mb-0"
                        errorMessage={field.state.meta.errors.join(", ")}
                        type={KroInputFieldType.TEXTADDONS}
                        placeHolder={"08012345678"}
                        fillColor="bg-neutral-50"
                        id={field.name}
                        name={field.name}
                        value={field.state.value}
                        onChange={(e) => {
                          if (isPhoneNumberVerified) {
                            form.setFieldValue("isPhoneNumberVerified", false);
                          }
                          return field.handleChange(e.target.value);
                        }}
                        addon={
                          isPhoneNumberVerified ? (
                            <img
                              src="/assets/icons/radio_checked.svg"
                              alt="checked"
                            />
                          ) : OTPRequestMutation.isPending ? (
                            <KroLoader />
                          ) : (
                            <KroButton.Base
                              title={"Verify"}
                              type="button"
                              isLoading={OTPRequestMutation.isPending}
                              className="px-3 py-[6px]"
                              hasIcon={false}
                              isDisabled={
                                field.state.meta.errors.length > 0 ||
                                adminPhoneNumber.length < 1
                              }
                              onclick={() => {
                                const util = new KroPhoneNumberUtil();
                                OTPRequestMutation.mutate({
                                  use_case: EOTPUseCases.ORG_SIGNUP,
                                  phone:
                                    util.getPhoneNumberWithCountryCode(
                                      adminPhoneNumber,
                                      phoneNumberCountryCode,
                                    ) ?? "",
                                });
                              }}
                            />
                          )
                        }
                      />
                    )}
                  />
                );
              }}
            </form.Subscribe>

            {isPhoneNumberVerified && (
              <div className="text-sm leading-snug mt-2 font-normal text-neutral-500">
                Your phone number has been verified!
              </div>
            )}
          </div>
        </div>

        <KroButton
          title={"Continue"}
          type="submit"
          isLoading={updateOrganizationStepOneMutation.isPending}
          isDisabled={!isPhoneNumberVerified}
          className="mt-9 w-full"
        />
      </form>
    </>
  );
};

export default OrganizationStepOne;
